<!--
 * @Author: your name
 * @Date: 2021-04-20 15:48:51
 * @LastEditTime: 2021-05-10 16:46:08
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \one\src\views\shareresume3\index.vue
-->
<template>
  <div class="allmsgclass">
    <navigation :noleft="true"></navigation>
    <div class="topmsges">
      <div class="topmsg">
        <div>推荐简历</div>
        <div></div>
      </div>
      <div class="buzh">
        <div class="hengxian"></div>
        <div class="dianxu">
          <div>1</div>
          <div>2</div>
          <div>3</div>
        </div>
      </div>
    </div>
    <div class="msgclass">
      <div>恭喜您</div>
      <div>您已成功分享了1位候选人</div>
      <div>喜获20余额</div>
      <div>
        <img src="../../assets/mon.png" alt="">
      </div>
      <!-- <div @click.stop="$refs.kefuid.showmeth()">升级高级认证企业可分享更多职位<span>去升级 ></span></div> -->
    </div>
    <!-- <kefu ref="kefuid"></kefu> -->
    <div class="fenxiang">
      <button @click="$router.go(-3)">确认</button>
    </div>
  </div>
</template>
<script>
// import kefu from "../../components/kefu/index";
export default {
  
};
</script>
<style scoped>
.fenxiang {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  font-size: 0.3rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #FF5F19;
  line-height: 0.42rem;
  padding: 0.29rem 0;
  background-color: #ffffff;
  border-top-color: #dfdfdf;
  border-top-style: solid;
  border-top-width: 1px;
}
.fenxiang button {
  background: #ffffff;
  border-radius: 0.16rem;
  border: 1px solid #FF5F19;
  padding: 0.28rem 0;
  text-align: center;
  width: 90%;
  margin-left: 5%;
}
.msgclass>div{
  text-align: center;
}
.msgclass>:first-child{
  font-size: .5rem;
  line-height: .7rem;
  margin-top: .16rem;
  color: #222222;
  font-weight: bold;
}
.msgclass>:nth-child(2){
  font-size: .38rem;
  line-height: .53rem;
  color: #6F6F6F;
  margin-top: .14rem;
  margin-bottom: .11rem;
}
.msgclass>:nth-child(3){
  font-size: .42rem;
  line-height: .59rem;
  color: #FF0000;
  font-weight: bold;
}
.msgclass>:nth-child(4) img{
  width: 70%;
  margin-top: .1rem;
}
.msgclass>:nth-child(5){
  margin: 0 .95rem;
  background-color: #FFF3EE;
  font-size: .24rem;
  line-height: .33rem;
  color: #222222;
  padding: .22rem .25rem;
  margin-top: .2rem;
}
.msgclass>:nth-child(5) span{
  margin-left: .3rem;
}
.topmsges {
  background-color: #ffffff;
  padding-bottom: 1.3rem;
}
.topmsg {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 0.38rem;
  padding-top: 0.16rem;
  background-color: #ffffff;
}
.topmsg > :first-child {
  font-size: 0.46rem;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #222222;
  line-height: 0.65rem;
}
.sh2 {
  padding: 0.13rem 0.32rem;
  font-size: 0.3rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
  line-height: 0.42rem;
  background: #eeeeee;
  border-radius: 0.1rem;
  position: relative;
}
.sh2 img {
  position: absolute;
  width: 0.12rem;
  bottom: 0.11rem;
  right: 0.13rem;
}
.buzh {
  margin: 0 1.12rem;
  position: relative;
  margin-top: 0.39rem;
  background-color: #ffffff;
}
.hengxian {
  height: 0.08rem;
  background: #FF5F19;
  position: absolute;
  width: 100%;
  top: 0.16rem;
  z-index: 1;
}
.dianxu {
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: center;
  line-height: 0.4rem;
  font-size: 0.28rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
  line-height: 0.38rem;
}
.dianxu > div {
  width: 0.4rem;
  height: 0.4rem;
  border-radius: 50%;
  background-color: #ff5f19;
  z-index: 2;
}

.dianxu > div:first-child::after {
  content: "上传简历";
  display: block;
  width: 2rem;
  margin-top: 0.2rem;
  margin-left: -0.8rem;
  font-size: 0.28rem;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #222222;
  line-height: 0.38rem;
}
.dianxu > div:nth-child(2):after {
  content: "完善简历";
  display: block;
  width: 2rem;
  margin-top: 0.2rem;
  margin-left: -0.8rem;
  font-size: 0.28rem;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #222222;
  line-height: 0.38rem;
}
.dianxu > div:nth-child(3):after {
  content: "完成简历推荐";
  display: block;
  width: 2rem;
  margin-top: 0.2rem;
  margin-left: -0.8rem;
  font-size: 0.28rem;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #222222;
  line-height: 0.38rem;
}
.allmsgclass{
  background-color: #ffffff;
  min-height: 100%;
}
</style>
